require('./bootstrap');
require('bootstrap-select');
require('bootstrap-select/js/i18n/defaults-pl_PL');
require('@fortawesome/fontawesome-free/js/all')
require('daterangepicker/daterangepicker')
require('moment').locale('pl');
require('ekko-lightbox');

$('.selectpicker').selectpicker();

$('input.datepicker').daterangepicker({
    "locale": {
        "format": "YYYY-MM-DD",
        "separator": " - ",
        "applyLabel": "Zatwierdź",
        "cancelLabel": "Anuluj",
        "fromLabel": "Od",
        "toLabel": "Do",
        "autoUpdateInput": false
    }
}, function(start, end, label) {
    let start_formatted = start.format('YYYY-MM-DD')
    let end_formatted = end.format('YYYY-MM-DD')

    $('#date_from').val(start_formatted)
    $('#date_to').val(end_formatted)
});

$('input.datepicker2').daterangepicker({
    "locale": {
        "format": "YYYY-MM-DD",
        "separator": " - ",
        "applyLabel": "Zatwierdź",
        "cancelLabel": "Anuluj",
        "fromLabel": "Od",
        "toLabel": "Do",
        "autoUpdateInput": false
    }
}, function(start, end, label) {
    let start_formatted = start.format('YYYY-MM-DD')
    let end_formatted = end.format('YYYY-MM-DD')

    $('#pz_date_from').val(start_formatted)
    $('#pz_date_to').val(end_formatted)
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
